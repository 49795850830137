import React from "react";
import { Helmet } from "react-helmet";
import { graphql, Link, useStaticQuery } from "gatsby";
import NoNavLayout from "../components/NoNavLayout";
import HeroSection from "../components/HeroSection";
import Img from "gatsby-image";

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "stockperks.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <NoNavLayout>
      <Helmet title="404: Not found" />
      <HeroSection>
        <Img
          fixed={data.placeholderImage.childImageSharp.fixed}
          alt="Stockperks Logo"
        />
        <h1>Page not found!</h1>
        <h3>Sorry, we couldn't find the page you were looking for.</h3>
        <Link to="/">
          <p>Go to home</p>
        </Link>
      </HeroSection>
    </NoNavLayout>
  );
};

export default NotFoundPage;
