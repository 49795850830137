import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import GlobalStyle from "./GlobalStyle";
import colors from "../utils/colors";

const NoNavLayout = ({ children, isDarkBackground }) => {
  return (
    <>
      <GlobalStyle />
      <PageContent isDarkBackground={isDarkBackground}>{children}</PageContent>
    </>
  );
};

NoNavLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isDarkBackground: PropTypes.bool
};

export default NoNavLayout;

const PageContent = styled.main`
  background: ${props => (props.isDarkBackground ? colors.cobalt : "none")};
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
`;
