import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import colors from "../utils/colors";
import { columnSize, windowSize } from "../utils/responsive";
import Img from "gatsby-image";

const HeroSection = ({ children, isDarkBackground }) => (
  <HeroSectionContainer isDarkBackground={isDarkBackground}>
    <HeroSectionContent isDarkBackground={isDarkBackground}>
      {children}
    </HeroSectionContent>
  </HeroSectionContainer>
);

HeroSection.propTypes = {
  children: PropTypes.node.isRequired,
  isDarkBackground: PropTypes.bool
};

export default HeroSection;

const HeroSectionContainer = styled.section`
  background-color: ${props => props.isDarkBackground ? colors.cobalt : colors.white};
  padding-top: 15px;
  width: 100%;
  min-height: 740px;
  display: flex;
  justify-content: center;
  align-items: center;
  h1, h2, h3 {
    color: ${props => props.isDarkBackground ? colors.white : colors.black};
  }
`;

const HeroSectionContentDark = css``;

const HeroSectionContentLight = css`
  p {
    margin-block-start: 0.45em;
    margin-block-end: 0.45em;
  }
`;

const HeroSectionContent = styled.div`
  margin: 0 auto;
  max-width: ${windowSize.large}px;
  padding: 30px;
  text-align: center;
  ${props =>
    props.isDarkBackground ? HeroSectionContentDark : HeroSectionContentLight}

`;

const HeroImage = styled(Img)`
  width: 400px;
  margin: 50px auto 0 auto;
  align-self: center;
  @media (max-width: ${windowSize.medium}px) {
    margin-top: 20px;
    width: 225px;
  }
`;

HeroImage.propTypes = {
  maxWidth: PropTypes.number
};

export { HeroImage };

export const HeroImageArticle = styled.img`
  margin: 50px auto 0 auto;
  max-height: 500px;
  max-width: ${columnSize.large}px;
  object-fit: contain;
  width: 100%;
`;

export const HeroProviderLogoImage = styled(Img)`
  width: 350px;
  margin: 0px auto 0 auto;
  align-self: center;
  @media (max-width: ${windowSize.medium}px) {
    width: 225px;
  }
`
